.achievements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 1rem;
  position: relative;
  .lineBreak {
    margin: 1rem;
    height: 1px;
    width: 100%;
    background-color: black;
  }
  .certs,
  .awards {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      font-weight: bold;
    }
    div {
      padding: 0;
      .certficate {
        width: auto;
        height: 250px;
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin: 0.5rem;
        padding: 0;
        .certOverlay {
          width: 100%;
          height: 100%;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .btns {
            position: absolute;
            bottom: 0.5rem;
            right: 1rem;
            display: none;
          }
          .viewButton,
          .urlButton {
            font-size: 1.3rem;
            padding: 0.2rem 0.5rem;
            outline: none;
            color: $primary;
            background-color: transparent;
            &:active {
              transform: translateY(1px);
            }
          }
          &:hover .btns {
            display: flex;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.7);
            cursor: pointer;
          }
        }
      }
    }
  }
}

.popupWrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  .popup {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: $primary;
    height: auto;
    .header {
      display: flex;
      justify-content: end;
      align-items: center;
      height: 30px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding: 1rem;
      i {
        cursor: pointer;
        font-size: 0.8rem;
        &:active {
          transform: translateY(1px);
        }
      }
    }
    .body {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 700px;
        margin: 0.5rem 0;
      }
    }
    .viewButton {
      display: block;
    }
    .footer {
      display: flex;
      justify-content: end;
      align-items: center;
      height: 50px;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding: 1rem;
      button {
        padding: 0.1rem 1rem;
        border-radius: 5px;
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: transparent;
        cursor: pointer;
        &:active {
          transform: translateY(1px);
        }
      }
    }
  }
}
