.about {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 5rem;
  .aboutBody {
    padding: 1rem;
    .para {
      display: flex;
      i {
        padding: 0.4rem 1rem;
        margin-right: 1rem;
        color: $secondary;
      }
      p {
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
  }
}
