.projectsBody {
  height: auto;
  width: 100%;
  .row {
    justify-content: center;
  }
  .row div {
    padding: 0;
  }
  .project {
    width: auto;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 0.5rem;
    padding: 0;
    .projectOverlay {
      width: 100%;
      height: 100%;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      color: $primary;
      h4 {
        display: none;
      }
      .btns {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        display: none;
        color: $primary;
      }
      .viewButton,
      .urlButton {
        font-size: 1.3rem;
        padding: 0.2rem 0.5rem;
        outline: none;
        color: $primary;
        background-color: transparent;
        &:active {
          transform: translateY(1px);
        }
      }
      &:hover .btns {
        display: flex;
      }
      &:hover h4 {
        display: flex;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
        cursor: pointer;
      }
    }
  }
}
