.skills {
  margin-bottom: 1rem;
  .sections {
    .skill {
      border: 1px solid rgba(0, 0, 0, 0.173);
      border-radius: 5px;
      padding: 0.5rem 1rem 0.5rem 0;
      .icon {
        width: 18%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 1rem;
        margin-left: 0.5rem;
      }
      .description {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
      }
      .progress-bar {
        transition: width 0.1s linear;
      }
    }
    .softSkills {
      font-weight: 500;
      .row {
        .softSkill {
          display: flex;
          i {
            margin: 0 0.5rem;
            margin-top: 0.3rem;
            color: $secondary;
          }
        }
      }
    }
    .lineBreak {
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      margin: 0.5rem 0;
    }
  }
}
