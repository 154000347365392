.home {
  // width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
    .intro {
      width: 327.65px;
      text-align: left;
      align-items: start;
      h2,
      h4 {
        font-family: 'Dancing Script', cursive;
        font-size: 2rem;
        font-weight: bold;
      }
    }
    img {
      width: 120%;
      height: 100%;
      transform: scale(1.2) translateX(100px);
    }
    .buttons {
      overflow: visible;
      flex-direction: row;
      button, a {
        border: 2px solid $secondary;
        outline: none;
        padding: 0.5rem 1rem;
        color: $secondary;
        background-color: $secondary;
        border-radius: 40px;
        color: $primary;
        transition: all 0.3s ease;
        font-weight: 500;
        margin: 0 .5rem;
        text-decoration: none;
        &:hover {
          color: $secondary;
          background-color: $primary;
        }
        &:active {
          transform: translateY(1px);
        }
      }
    }
  }
}

// Mobile View
@media (width <= 576px) {
  .home .img {
    display: none;
  }
}

@media (width > 576px) {
  .home .homeConnect {
    display: none;
  }
}
