.qualification div {
  overflow-y: auto;
  i {
    color: $secondary;
    padding: 0.4rem 1rem;
    margin-right: 1rem;
  }
}
.qualification {
  margin-bottom: 1rem; 
  .experience {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
    font-weight: 500;
  }
}
