.sidebar {
  z-index: 99;
  width: 350px;
  height: 100vh;
  background-color: $dark;
  color: $primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: none;
      color: $primary;
      background-color: $dark;
      padding: 15px;
      transition: all 0.3s ease;
      &:hover {
        color: $secondary;
      }
    }
  }
  .avatar {
    margin-bottom: 0.5rem;
    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }
  }
  .name{
    font-size: 2rem;
    margin-bottom: 0;
  }
  .options {
    width: 200px;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    padding: 1rem 0;
    a {
      text-decoration: none;
      color: $primary;
      .active {
        border: 1px solid $secondary;
        color: $secondary;
        border-radius: 5px;
      }
    }
    div {
      display: flex;
      align-items: center;
      margin: 0.4rem 0;
      cursor: pointer;
      padding: 0.1rem;
      border: 1px solid $dark;
      transition: all 0.5s ease;
      &:hover {
        border: 1px solid $secondary;
        color: $secondary;
        border-radius: 5px;
      }

      i {
        width: 3rem;
        display: flex;
        justify-content: center;
        margin: 0 0.5rem 0 0.2rem;
        font-size: 1.5rem;
      }
      p {
        margin: 0;
      }
    }
  }
}

// Mobile View
@media (width <= 576px) {
  .sidebar {
    position: absolute;
    left: -100%;
    top: 0;
  }
}

@media (width > 576px) {
  .sidebar .close {
    display: none;
  }
}
