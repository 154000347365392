.wrapper {
  width: 100%;
  height: 100%;
}

h1.heading {
  border-bottom: 3px solid $secondary;
}
.heading {
  display: flex;
  justify-content: start;
  h1 {
    border-bottom: 2px solid $secondary;
    margin-left: 0.7rem;
    font-weight: bold;
  }
}

#connect {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  i {
    font-size: 2.2rem;
    margin: 0 1rem;
    padding: 0;
    cursor: pointer;
    transition: all 0.5s ease;
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .fa-linkedin {
    color: #0a66c2;
  }
  .fa-hackerrank {
    color: #2ec866;
  }
}

h4 {
  font-weight: bold;
}
