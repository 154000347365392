// Variables
$secondary: #4361ee;
$primary: #ffffff;
$dark: #000000;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Darker Grotesque', sans-serif;
}

.App {
  width: 100dvw;
  height: 100dvh;
  display: flex;
}

@import './common.scss';
@import './pages/sidebar.scss';
@import './pages/home.scss';
@import './pages/floatingMenu.scss';
@import './pages/about.scss';
@import './pages/skills.scss';
@import './pages/achievements.scss';
@import './pages/projects.scss';
@import './pages/qualification.scss';
